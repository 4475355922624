<template>
    <section class="navbar-layout flex-direction-column" v-show="skinStore.skin">
        <el-config-provider :locale="zhCn">
            <div class="navbar navbar-screen clearfix">
                <section class="flex-ver-center unit-choose" v-if="route.path.indexOf('monitor') == -1">
                    <el-popover placement="bottom" width="auto" trigger="hover" popper-class="owner-popover-box" v-if="ownerStore.ownerList.length > 0">
                        <template #reference>
                            <span class="process-btn font-14">{{ ownerStore.ownerInfo?.name }}</span>
                        </template>
                        <InjectOwnerTree />
                    </el-popover>
                </section>
                <div class="navbar-left float-left">
                    <section class="flex-ver-center height-full">
                        <el-icon class="navbar-menu-icon pointer" @click="fn.openDialogHandle"><i-menu /></el-icon>
                        <section v-if="menuItem">当前：
                            <span class="font-bolder" style="color:var(--primary-color-light)">{{ menuItem }}</span>
                        </section>
                    </section>
                </div>
                <section class="navbar-right flex float-right">
                    <section class="height-full flex-direction-column flex-hor-center" style="color:var(--primary-color);margin-right:20px">
                        <section>{{ data.date }} {{ data.day }}</section>
                        <section class="text-right">{{ data.time }}</section>
                    </section>
                    <section class="flex-ver-center m-r-20">
                        <ClientOnly>
                            <section @click="fn.goEventPage" class="cursor-pointer">
                                <el-badge type="warning" :hidden="alarmCount == 0"
                                    :value="alarmCount > 99 ? '99+' : alarmCount" class="item">
                                    <img src="@/assets/images/icon/bell.png" alt="" />
                                </el-badge>
                            </section>
                        </ClientOnly>
                    </section>
                    <el-dropdown trigger="click" class="userinfo-drop">
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item>
                                    <span style="display: block">修改密码</span>
                                </el-dropdown-item>
                                <el-dropdown-item divided>
                                    <router-link class="process-btn" style="display: block" to="/logout">退出登录</router-link>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                        <section class="flex-all-center">
                            <!-- <div class="avatar-wrapper flex-all-center">
                                <img src="~~/assets/images/avatar.png" class="user-avatar" />
                            </div> -->
                            <ClientOnly>
                                <div class="username text-ellipsis flex-dynamic-full">
                                    {{ data.username }}
                                </div>
                            </ClientOnly>
                        </section>
                    </el-dropdown>
                </section>
                <div class="navbar-center flex-dynamic-full">
                    <div class="nav-title">
                        <span class="font-34 text font-bolder block text-center">{{ userStore.platform_title }}</span>
                        <!-- <span class="font-18 text block text-center">{{ userStore.platform_subtitle }}</span> -->
                    </div>
                </div>
            </div>
            <slot />
            <AlarmNotification />
            <InjectDrawer
                :show="data.dialogShow"
                direction="ltr"
                width="200"
                @close="fn.closeDialogHandle"
            >
                <Sidebar :data="data.dialogData" />
            </InjectDrawer>
        </el-config-provider>
    </section>
</template>
<script>
import AlarmNotification from '@/views/common/alarm.vue';
import Sidebar from '@/views/common/sidebar.vue';
export default {
    components: {
        AlarmNotification,
        Sidebar,
    }
}
</script>
<script setup>
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import pageinfo from '~~/config/pageinfo';
import { useUserStore } from '~~/store/user';
import { useOwnerStore } from '~~/store/owner';
import { useSkinStore } from '~~/store/skin';
import { useEventStore } from '~~/store/event';
import dayConfig from '~~/data/day';
import menuConfig from '~~/data/navbarmenu';

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const ownerStore = useOwnerStore();
const skinStore = useSkinStore();
const eventStore = useEventStore();
useHead({
    title: pageinfo[route.name]?.title || userStore.platform_title,
})
const menuItem = computed(() => {
    return menuConfig[route.meta.menu]?.text || null;
})
const data = reactive({
    date: dayjs().format('YYYY-MM-DD'),
    time: dayjs().format('HH:mm:ss'),
    day: '星期' + dayConfig[dayjs().day()],
    activeIndex: route.path,
    username: '',
    dialogShow: false,
    dialogData: {},
    dialogType: '',
});
const alarmCount = computed(() => {
    return eventStore.eventList?.total?.length || 0;
})
const fn = {
    goEventPage() {
        router.push('/events');
    },
    checkSkin() {
        if (!skinStore.skin) {
            skinStore.getSkin();
        }
    },
    closeDialogHandle(flag) {
        data.dialogType = '';
        data.dialogShow = false;
        data.dialogData = null;
        if (flag) {
            fn.fetchTableData();
        }
    },
    openDialogHandle(info, type) {
        data.dialogType = type;
        data.dialogData = info;
        data.dialogShow = true;
    },
    goPage(url) {
        router.push(url);
    }
};
onMounted(() => {
    if (process.client) {
        data.username = userStore.userinfo?.account?.name;
        fn.checkSkin();
    }
    setInterval(() => {
        data.time = dayjs().format('HH:mm:ss');
    }, 1000);
})
</script>

<style lang="scss" scoped>
.username {
    background: url('/images/admin.png') left center no-repeat;
    background-size: auto 100%;
    padding: 14px 10px 14px 45px;
    min-width: 130px;
}
.navbar-menu-icon {
    width: 28px;
    height: 28px;
    margin-right: 10px;
    svg {
        width: 100%;
        height: 100%;
    }
}
.unit-choose {
    position: absolute;
    bottom: 0;
    left: 10px;
    color: var(--primary-color);
    z-index: 2;
}
</style>